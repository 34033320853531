import Fuse from 'fuse.js'
import React, { useState, useRef, useMemo } from "react";
import {
  Link
} from "react-router-dom";

import { Text, Center, Grid, Col, Paper, Button, Group, TextInput } from '@mantine/core';
import { useTheme } from '../util/states';

export default function Search(properties) {
  const [searchValue, setSearchValue] = useState();
  const [overlay, setOverlay] = useState();
  let {searchData, cryptosData, rankings} = properties;

  const op = useRef(null);

  if (!searchData || !cryptosData) {
    return <p>loading search...</p>;
  }

  const itemTemplate = (data) => {
    let input = data.item ? data.item : data;
    let name = cryptosData && cryptosData[input.id]
                ? cryptosData[input.id].name
                : '?';

    return (
      <div className="p-col">
        <Link
          key={`${input.id} link`}
          to={`/coin/${input.id}`}
          onClick={eraseSearch}
          style={{'color': 'black', 'textDecoration': 'none'}}
        >
          <img
            src={`/img/thumb/${input.id}.webp`}
            style={{'float': 'left', 'paddingRight': '5px', 'paddingLeft': '5px'}}
            alt={`${name} logo`}
          />
          <span style={{'fontWeight': 'bold'}}>
          {
            `${name} `
          }
          </span>
          <span>
          {
            `(${input.id && cryptosData && cryptosData[input.id] ? (cryptosData[input.id].symbol).toUpperCase() : '?'})`
          }
          </span>
        </Link>
      </div>
    );
  };

  const updateSearchValue = (event) => {
    setSearchValue(event.target.value);
    setOverlay();

    const fuse = new Fuse(
      searchData,
      {
        includeScore: true,
        keys: ['name']
      }
    );

    const result = fuse.search(event.target.value)
    if (result && result.length > 0) {
      setOverlay(
        result.slice(0,5).map(crypto => {
          if (crypto.item && crypto.item.id) {
            return itemTemplate(crypto)
          } else {
            return null;
          }
        }).filter(x => x)
      );
    } else {
      eraseSearch(event);
    }
  };

  const eraseSearch = (event) => {
    setSearchValue();
    setOverlay();
  };

  return ([
            <TextInput
              key="searchInput"
              label="Cryptocurrency name"
              value={searchValue ? searchValue : ''}
              onChange={updateSearchValue}
              sx={{marginBottom: '20px', marginTop: '10px', marginLeft: 'auto'}}
            />,
            <Paper padding="md" shadow="xs">
              <Group direction="column" aria-label="search result list">
                {
                  overlay
                }
              </Group>
            </Paper>
          ]);


}

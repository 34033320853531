/**
 * src/firebase.js
 */
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBnKz_MFjBmpgxrDusU8wvIEfDqwF_L9Lk",
  authDomain: "cryptoindex-6757a.firebaseapp.com",
  projectId: "cryptoindex-6757a",
  appId: "1:122650380676:web:cd901718cf6a1fbde835d1",
  measurementId: "G-EHJVGVK6B3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export { auth };

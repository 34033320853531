import React, { useEffect } from "react";
import { useQuery } from 'react-query';
import notepack from './notepack';
import { Buffer } from 'buffer';
const axios = require('axios');

function useQueryHook(
  url,
  dataType="JSON",
  axiosMode="GET",
  axiosParameters={},
  token="",
  queryName,
  functionToSet,
  queryConfig={}
) {
  const { status, data, error, isFetching } = useQuery(
    queryName,
    async () => {
      axios.defaults.headers.common['Authorization'] = token;

      let response = axiosMode === "GET"
        ? await axios.get(url, axiosParameters)
        : await axios.post(url, axiosParameters)

      if (dataType === "binary") {
        const data = new Buffer.from(response.data);
        return notepack.decode(new Buffer.from(data));
      } else {
        return response.data;
      }
    },
    queryConfig
  );

  useEffect(() => {
    if (data && !isFetching && !error) {
      functionToSet(data);
    }
  }, [data, isFetching, error]);
}

export { useQueryHook };

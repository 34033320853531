import "./App.css";
import React, { Suspense, useState, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import { Helmet } from 'react-helmet';

import {
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  Group,
  Center,
  Menu,
  MantineProvider,
  NormalizeCSS,
  GlobalStyles,
  Grid,
  Col,
  Container,
  Divider,
  Text,
  Button,
  ActionIcon,
  TypographyStylesProvider,
  ColorSchemeProvider,
  Title
} from '@mantine/core';

import { useTheme } from './util/states';
import { IoSunnyOutline, IoMoonOutline } from "react-icons/io5";

import Search from "./components/search";
import { auth } from "./util/firebase";
import { useQueryHook } from "./util/use-query-hook";

const Coin = React.lazy(() => import("./pages/coin.js"));
const About = React.lazy(() => import("./pages/about.js"));
const MainPage = React.lazy(() => import("./pages/main-page.js"));
const TermsOfService = React.lazy(() => import("./pages/terms-of-service.js"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacy-policy.js"));

export default function App() {
  const queryClient = new QueryClient();
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <CryptoIndex />
      </QueryClientProvider>
    </Router>
  );
}

function CryptoIndex() {
  const queryClient = useQueryClient()

  // Data serialization
  const [cryptosData, setCryptosData] = useState();
  const [searchData, setSearchData] = useState();

  // Login SearchFile
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginType, setLoginType] = useState();
  const [token, setToken] = useState();
  const [name, setName] = useState();

  // hCaptcha
  const [captchaNeeded, setCaptchaNeeded] = useState(true);
  const [captchaToken, setCaptchaToken] = useState();
  const [captchaEKey, setCaptchaEKey] = useState();

  // Index ranking
  const [rankings, setRankings] = useState([]);

  // Analytics
  const [currentTitle, setCurrentTitle] = useState("CryptoIndex - Today's index of most voted for Cryptocurrencies");

    // authentication
  auth.onIdTokenChanged(async user => {
    if (user) {
      let userIdToken = await auth.currentUser.getIdToken();
      if (userIdToken) {
        setToken(userIdToken);
        setName(user.displayName ? user.displayName : 'Anonymous');
        setLoginType(user.displayName ? 'Twitter' : 'Anonymous');
        setLoggedIn(true);
      }
    }
  });

  useQueryHook(
    //`https://us-central1-cryptoindex-6757a.cloudfunctions.net/cryptos/`, // url
    `/cryptos`, // url
    //`http://localhost:8084/cryptos`, // url
    'JSON', // data type
    'GET', // axios mode
    {}, // axios config
    "", // token
    'rankings',
    setRankings,
    {refetchInterval: 60000}
  );

  useQueryHook(
    '/data/cryptoData',
    'binary',
    'GET', // axios mode
    {
      responseType: 'arraybuffer'
    }, // axios config
    "", // token
    'cryptos',
    setCryptosData,
    {}
  );

  useQueryHook(
    '/data/search',
    'binary',
    'GET', // axios mode
    {
      responseType: 'arraybuffer'
    }, // axios config
    "", // token
    'search',
    setSearchData,
    {}
  );

  useEffect(() => {
    document.title = currentTitle;
  }, [currentTitle])

  /*
  let marqueeCoinItems = useMemo(() => rankings.length > 0
                                        ? rankings.map(({id, score}) => {
                                            return score > 0
                                              ? <MarqueeItem key={`${id} Marquee`} id={id} data={cryptosData[id]} />
                                              : null
                                          })
                                        : [], [rankings]);

  let iconMarqueeCoinItems = useMemo(() => rankings.length > 0
                                        ? rankings
                                            .map(({id, score}) => {
                                              return score > 0
                                                ? <IconMarqueeItem key={`${id} IconMarquee`} id={id}/>
                                                : null
                                            })
                                        : [], [rankings]);
  */

  const [colorScheme, setColorScheme] = useTheme();
  const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        theme={{ colorScheme }}
      >
        <GlobalStyles />
        <TypographyStylesProvider sx={{textDecoration: "none"}}>
          <AppShell
            fixed
            header={
              <Header height={70} padding="sm">
                {/* You can handle other responsive styles with MediaQuery component or createStyles function */}
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <Title order={2}>
                    <Link to="/">
                      <a style={{color: colorScheme === 'light' ? 'black' : 'white'}}>
                        CryptoIndex (Season 2)
                      </a>
                    </Link>
                  </Title>

                  <ActionIcon
                    onClick={() => toggleColorScheme()}
                    color={colorScheme === 'dark' ? 'yellow' : 'blue'}
                    variant="hover"
                    style={{marginLeft: 'auto'}}
                    title="Toggle color scheme"
                  >
                    {
                      colorScheme === 'dark'
                        ? <IoSunnyOutline/>
                        : <IoMoonOutline/>
                    }
                  </ActionIcon>
                </div>
              </Header>
            }
          >
            <Helmet>
              <title>CryptoIndex</title>
              <meta name="description" content="CryptoIndex" />
            </Helmet>
            <Container fluid>
              <Grid grow key={"Routes"}>
                <Col span={12}>
                  <Routes>
                    <Route
                      path="/about"
                      element={
                        <Suspense fallback={<div>loading about ...</div>}>
                          <About setCurrentTitle={setCurrentTitle}/>
                        </Suspense>
                      }
                    />

                    <Route
                      path="/coin/:id"
                      element={
                        <Suspense fallback={<div>loading voting page ...</div>}>
                          <Coin
                            // Login
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                            loginType={loginType}
                            setLoginType={setLoginType}
                            name={name}
                            setName={setName}
                            token={token}
                            setToken={setToken}
                            // hCaptcha
                            captchaNeeded = {captchaNeeded}
                            setCaptchaNeeded = {setCaptchaNeeded}
                            captchaToken = {captchaToken}
                            setCaptchaToken = {setCaptchaToken}
                            captchaEKey = {captchaEKey}
                            setCaptchaEKey = {setCaptchaEKey}
                            // Analytics
                            setCurrentTitle = {setCurrentTitle}
                            // Data
                            cryptosData={cryptosData}
                            rankings={rankings}
                          />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/tos"
                      element={
                        <Suspense fallback={<div>loading terms of service ...</div>}>
                          <TermsOfService setCurrentTitle={setCurrentTitle}/>
                        </Suspense>
                      }
                    />

                    <Route
                      path="/search"
                      element={
                        <Suspense fallback={<div>loading search ...</div>}>
                          <Search
                            searchData={searchData}
                            cryptosData={cryptosData}
                            rankings={rankings}
                          />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/privacypolicy"
                      element={
                        <Suspense fallback={<div>loading privacy policy ...</div>}>
                          <PrivacyPolicy setCurrentTitle={setCurrentTitle}/>
                        </Suspense>
                      }
                    />

                    <Route
                      path="/"
                      element={
                        <Suspense fallback={<div>loading index ...</div>}>
                          <MainPage setCurrentTitle={setCurrentTitle} rankings={rankings} setRankings={setRankings} cryptosData={cryptosData} />
                        </Suspense>
                      }
                    />
                  </Routes>
                </Col>
              </Grid>

              <Grid grow key={"Footer"}>
                <Col span={12}>
                  <Group position={"center"}>
                    <Link to="/about">About</Link>
                    <Divider orientation="vertical" />
                    <Link to="/tos">Terms of use</Link>
                    <Divider orientation="vertical" />
                    <Link to="/privacypolicy">Privacy policy</Link>
                    <Divider orientation="vertical" />
                    <Link to="/search">Search</Link>
                    <Divider orientation="vertical" />
                    <a href="https://twitter.com/cryptoindex">Follow on Twitter</a>
                  </Group>
                </Col>
              </Grid>
            </Container>
        </AppShell>


      </TypographyStylesProvider>
    </MantineProvider>
  </ColorSchemeProvider>
  );
}
